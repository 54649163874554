// Vendors
import React from 'react';
// Components
import { CommercialCardComponent } from '@openbank/cb-ui-commons';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
// Configurations
import { getErrorLinkConfiguration } from 'configurations/app.configurations';
import { getPPILandingButtonConfiguration } from './configurations/financing-ppi-landing-button.configuration';
import { getPPILandingCardBaseConfiguration } from './configurations/financing-ppi-landing.configuration';
import { getPPILandingLinkContractConfiguration } from './configurations/financing-ppi-landing-link.configuration';
import { getPPILandingStatusConfiguration } from './configurations/financing-ppi-landing-status.configuration';
import { getPPILandingTagConfiguration } from './configurations/financing-ppi-landing-tag.configuration';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import { useNavigate } from 'react-router-dom';
import { useFinancingPPILandingHook } from './hooks/financing-ppi-landing.hook';
// Utilities
import { getCardHelperMessage } from './utils/financing-ppi-landing.utils';

const FinancingPPILandingComponent = (): React.ReactElement => {
  const {
    customerContractingEnabled,
    error,
    fetching,
    formatMessage,
    handleCancelPPIContractButtonClick,
    handleContractPPIButtonClick,
    handleViewTermsPolicyLinkClick: onClick,
    navigate,
    ppiPercentage,
    status,
    ...props
  } = useFinancingPPILandingHook();

  return (
    <ErrorWrapperComponent
      {...{
        fetching,
        error,
        linkConfiguration: getErrorLinkConfiguration({
          formatMessage,
          route: RoutesEnumeration.FINANCING,
          navigate,
        }),
      }}
    >
      <CommercialCardComponent
        {...getPPILandingCardBaseConfiguration({ formatMessage, ppiPercentage })}
        buttonConfiguration={getPPILandingButtonConfiguration({
          customerContractingEnabled,
          formatMessage,
          handleCancelPPIContractButtonClick,
          handleContractPPIButtonClick,
          status,
        })}
        statusConfiguration={{
          ...getPPILandingLinkContractConfiguration({ formatMessage, onClick, status }),
          helperText: getCardHelperMessage({ formatMessage, status }),
          statusInfo: getPPILandingStatusConfiguration({ ...props, formatMessage, status }),
          tagConfiguration: getPPILandingTagConfiguration({ formatMessage, status }),
        }}
      />
    </ErrorWrapperComponent>
  );
};

export { FinancingPPILandingComponent };
