// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import WizardComponent from 'components/wizard/wizard.component';
import FinancingOperativeLayoutComponent from 'containers/financing/components/financing-operative-layout/financing-operative-layout.component';
import FinancingPaymentMethodSidebarComponent from './components/sidebar/financing-payment-method-sidebar.component';
// Configurations
import { getErrorLinkConfiguration } from 'configurations/app.configurations';
// Constants
import {
  PAYMENT_METHOD_WIZARD_ERROR_PROPS,
  PAYMENT_METHOD_WIZARD_STEPS_PROPS,
  PAYMENT_METHOD_WIZARD_SUCCESS_PROPS,
} from './constants/financing-payment-method.constants';
// Contexts
import { FinancingPaymentMethodContextConsumerHOC } from './contexts/financing-payment-method.context';
// Enumertaions
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import FinancingPaymentMethodHook from './hooks/financing-payment-method.hook';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';

const FinancingPaymentMethodBaseView = (): React.ReactElement => {
  const { billingCyclePeriodicity, error, fetching, formatMessage, navigate } =
    FinancingPaymentMethodHook();

  return (
    <ErrorWrapperComponent
      {...{
        fetching,
        error,
        linkConfiguration: getErrorLinkConfiguration({
          formatMessage,
          route: RoutesEnumeration.FINANCING,
          navigate,
        }),
      }}
    >
      <FinancingOperativeLayoutComponent sidebar={<FinancingPaymentMethodSidebarComponent />}>
        <WizardComponent
          steps={PAYMENT_METHOD_WIZARD_STEPS_PROPS}
          feedbackConfiguration={{
            errorConfiguration: PAYMENT_METHOD_WIZARD_ERROR_PROPS,
            successConfiguration: {
              ...PAYMENT_METHOD_WIZARD_SUCCESS_PROPS,
              descriptionValues: { day: billingCyclePeriodicity },
            },
          }}
        />
      </FinancingOperativeLayoutComponent>
    </ErrorWrapperComponent>
  );
};

const FinancingPaymentMethodView = FinancingPaymentMethodContextConsumerHOC(
  FinancingPaymentMethodBaseView
);

export { FinancingPaymentMethodView };
