// Constants
import {
  FINANCING_PPI_LANDING_CANCEL_BUTTON_PROPS,
  FINANCING_PPI_LANDING_CONTRACT_BUTTON_PROPS,
} from './constants/financing-ppi-landing-button.configuration.constants';
// Enumerations
import { PpiStatusEnumeration } from 'containers/financing/views/ppi/enumerations/ppi-status.enumeration';
// Translations
import {
  CANCEL_PPI_BUTTON,
  CONTRACT_PPI_BUTTON,
} from './translations/financing-ppi-landing-button.configuration.translations';
import {
  FinancingPPILandingButtonBuilderType,
  FinancingPPILandingButtonReturnType,
} from './types/financing-ppi-landing-button.configuration.type';

const getPPILandingButtonContractConfiguration = ({
  customerContractingEnabled,
  formatMessage,
  handleContractPPIButtonClick,
  status,
}: Omit<FinancingPPILandingButtonBuilderType, 'handleCancelPPIContractButtonClick'>) =>
  [PpiStatusEnumeration.EXPIRED, PpiStatusEnumeration.UNHIRED].includes(
    status as PpiStatusEnumeration
  ) && {
    ...FINANCING_PPI_LANDING_CONTRACT_BUTTON_PROPS,
    children: formatMessage({ id: CONTRACT_PPI_BUTTON }),
    disabled: !customerContractingEnabled,
    onClick: handleContractPPIButtonClick,
  };

const getPPILandingButtonCancelConfiguration = ({
  formatMessage,
  handleCancelPPIContractButtonClick,
  status,
}: Pick<
  FinancingPPILandingButtonBuilderType,
  'formatMessage' | 'handleCancelPPIContractButtonClick' | 'status'
>) =>
  [
    PpiStatusEnumeration.ACTIVE,
    PpiStatusEnumeration.COOLING_OFF,
    PpiStatusEnumeration.CREATED,
  ].includes(status as PpiStatusEnumeration) && {
    ...FINANCING_PPI_LANDING_CANCEL_BUTTON_PROPS,
    children: formatMessage({ id: CANCEL_PPI_BUTTON }),
    onClick: handleCancelPPIContractButtonClick,
  };

const getPPILandingButtonCoveredConfiguration = ({
  formatMessage,
  status,
}: Pick<FinancingPPILandingButtonBuilderType, 'formatMessage' | 'status'>) =>
  [PpiStatusEnumeration.TEMPORAL].includes(status as PpiStatusEnumeration) && {
    children: formatMessage({ id: CONTRACT_PPI_BUTTON }),
    disabled: true,
  };

const getPPILandingButtonConfiguration = ({
  handleCancelPPIContractButtonClick,
  handleContractPPIButtonClick,
  ...props
}: FinancingPPILandingButtonBuilderType): FinancingPPILandingButtonReturnType =>
  getPPILandingButtonContractConfiguration({ handleContractPPIButtonClick, ...props }) ||
  getPPILandingButtonCancelConfiguration({ handleCancelPPIContractButtonClick, ...props }) ||
  getPPILandingButtonCoveredConfiguration(props) ||
  {};

export { getPPILandingButtonConfiguration };
