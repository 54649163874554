// Vendors
import { useNavigate } from 'react-router-dom';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event Handlers
import { FinancingPPILandingHandlers } from '../handlers/financing-ppi-landing.handlers';
import { FinancingPPILandingTrackingHandlers } from '../handlers/financing-ppi-landing.handlers.tracking';
// Hooks
import { useFinancingPPIHook } from '../../../hooks/financing-ppi.hook';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIReturnHookType } from './types/financing-ppi-landing.hook.type';

const useFinancingPPILandingHook = (): FinancingPPIReturnHookType => {
  const { formatDate, formatMessage } = useCbIntl();

  const { setToastConfiguration } = ToastContextConsumerHook(),
    { PPIInfo } = useFinancingPPIContextConsumerHook(),
    { customerContractingEnabled, endCoverageDate, ppiPercentage, status } = PPIInfo || {};

  const { fetching, error } = useFinancingPPIHook();

  return {
    ...FinancingPPILandingHandlers({
      ...AppTrackingHook(FinancingPPILandingTrackingHandlers),
      navigate: useNavigate(),
      setToastConfiguration,
    }),
    customerContractingEnabled,
    endCoverageDate,
    error,
    fetching,
    formatDate,
    formatMessage,
    navigate: useNavigate(),
    ppiPercentage,
    status,
  };
};

export { useFinancingPPILandingHook };
